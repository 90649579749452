import "./App.css";
import Web3 from "web3";
import Web3Modal from "web3modal";
import Unity, { UnityContext } from "react-unity-webgl";
import React, { useEffect, useState } from "react";
import swal from "sweetalert2";

import { ContractFuncWrapper } from "./WrappedContractFunc";
// import VConsole from "vconsole";

import RequestLoginTypeHandler from "./unity/RequestLoginTypeHandler";
import RequestBitkubAuthenticationInfoHandler from "./unity/RequestBitkubAuthenticationInfoHandler";
import { useHistory } from "react-router-dom";
import { UnityEvent, UnityGameObject, UnityMethod } from "./model/UnityConstant";
import { Loading, ErrorPage, StartGame } from "./component/common/components";
import NavigateToLoginHandler from "./unity/NavigateToLoginHandler";
import useCheckAuthentication from "./hook/useCheckAuthentication";
import { getChainSetting } from "./model/NetworksDetail";
import StorageKey from "./model/StorageKey";
import { setupChainNetworkMetaMask, getBalanceMetaMask, getAccounts } from "./utility/metamask";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import queryString from "query-string";

import {
    getMachineFingerprint,
    generateScreenUID,
} from "./ClientIdentifier";

const RPC_TESTNET = "https://rpc-testnet.bitkubchain.io";
const RPC_MAINNET = "https://bitkub-chain-rpc.morningmoonvillage.com";
// const RPC_MAINNET = "https://rpc.bitkubchain.io";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDEOkyD57h5vDRYsrDD28HIxAwdVUcEqYg",
    authDomain: "morningmoonvillage-cbt.firebaseapp.com",
    projectId: "morningmoonvillage-cbt",
    storageBucket: "morningmoonvillage-cbt.appspot.com",
    messagingSenderId: "290178115954",
    appId: "1:290178115954:web:f48de2a4f0117782a895eb",
    measurementId: "G-GYVMW8PV92"
};

const annoucementImg = process.env.PUBLIC_URL + '/assets/images/game/announcement.png';

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const config = {
    allowFullscreen: true,
    backendURL: process.env.REACT_APP_MMV_BACKED_API_URL,
};

const fullscreenStyle = {
    height: "100%",
    width: "100%",
};

const providerOptions = {};
const web3Modal = new Web3Modal({
    network: "mainnet",
    cacheProvider: true,
    providerOptions,
});
let web3Instance = null;

const buildId = process.env.REACT_APP_BUILD_ID || Date.now()
const unityContext = new UnityContext({
    companyName: "X10 vs BBT",
    productName: 'morning-moon',
    productVersion: buildId,
    loaderUrl: `unitybuild/web.loader.js?t=${buildId}`,
    dataUrl: `unitybuild/web.data?t=${buildId}`,
    frameworkUrl: `unitybuild/web.framework.js?t=${buildId}`,
    codeUrl: `unitybuild/web.wasm?t=${buildId}`,
});

let limuContract = null;
// create vconsole instance...
// new VConsole();

let originalChainId = 0;

/**
 * 
 * @param {()=>{}} onContinue 
 */
function handleShowAnnouncement(onContinue) {
    swal.fire({
        imageUrl: `${annoucementImg}`,
        width: 1000,
        imageWidth: 1000,
        imageHeight: 500,
        background: "transparent",
        showConfirmButton: true,
        showCancelButton: false,
        showDenyButton: true,
        customClass: {
            confirmButton: "announcement-confirm",
            denyButton: "announcement-confirm",
        },
        denyButtonText: "Manual",
    }).then((r) => {
        console.log(">>>>>>>>>>>>>>>> r:", r);
        if (r.isConfirmed || r.isDismissed) {
            if (onContinue) onContinue();
        } else {
            window.open("https://whitepaper.morningmoonvillage.com/manual/early-access");
            handleShowAnnouncement(onContinue);
        }
    });
}
const machineFingerprint = getMachineFingerprint();
const screenUID = generateScreenUID();

function App(props) {
    const history = useHistory();
    const [isLoaded, setIsLoaded] = useState(false);
    const [progress, setProgress] = useState(0);
    const [forceNoShadow, setForceNoShadow] = useState(false);
    const isAuthenticated = useCheckAuthentication();
    const [isShowAnnouncement, setIsShowAnnouncement] = useState(true);
    const [isLoadCaptcha, setIsLoadCaptcha] = useState(false);

    const [didError, setDidError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const analytics = getAnalytics();

    useEffect(() => {
        if (isAuthenticated && analytics) {
            logEvent(analytics, 'unity_load_start');
        }
    }, [isAuthenticated]);

    useEffect(async () => {
        const chainDetail = getChainSetting()

        if (localStorage.getItem(StorageKey.LONGIN_TYPE) === "metamask") {
            const valid = await setupChainNetworkMetaMask(chainDetail)
            if (!valid) return

            const { accounts, error } = await getAccounts()
            if (error) {
                await swal.fire({
                    title: "Transaction Failed 000x3",
                    text: error.message,
                })
                return
            }
            if (accounts.length === 0) {
                await swal.fire({
                    title: "Transaction Failed 000x1",
                    text: "Sorry, the transaction cannot be performed at this time. Please try again",
                })
                return
            }

            const { coinBalance, error_balance } = await getBalanceMetaMask(accounts)
            if (error_balance) {
                await swal.fire({
                    title: "Transaction Failed 000x2",
                    text: "Sorry, the transaction cannot be performed at this time. Please try again",
                })
                return
            }
            if (coinBalance === 0) {
                await swal.fire({
                    title: 'You have 0 KUB.',
                    html: `You will need KUB coin in order to complete any transactions in Morning Moon Village.<br><br>
                    You can buy KUB from Gate.io, CoinEx, Mexc or Bitkub exchange.<br>(1 KUB is enough to play several weeks.)
                    <br> <br><div style="max-width:300px; margin: auto;">
                    <img src="/assets/images/game/exchange-removebg-preview.png" width='100%'/> </div>`,
                    imageUrl: "/assets/images/game/kub_crop.jpeg",
                    width: 800,
                    imageWidth: 260,
                    imageAlt: 'Custom image',
                    background: "rgb(229 229 229)",
                    showConfirmButton: true,
                    showCancelButton: false,
                    showDenyButton: true,
                    customClass: {
                        confirmButton: "popup-kub",
                        denyButton: "popup-kub",
                    },
                    denyButtonText: "Buy KUB",
                }).then((r) => {
                    if (r.isConfirmed || r.isDismissed) {
                    } else {
                        window.open("https://whitepaper.morningmoonvillage.com/manual/how-to-convert-and-transfer-token/kub2");
                    }
                });
            }
        }

        window.grecaptcha.ready(() => {
            console.debug("recaptcha v2 is ready");
        });

        handleShowAnnouncement(() => {
            setIsShowAnnouncement(false);
        });

        const parsedQs = queryString.parse(props.location.search);
        if (parsedQs["forceNoShadow"]) {
            const v = parsedQs["forceNoShadow"];
            setForceNoShadow(v.toLowerCase() === "true")
        }

    }, []);

    useEffect(() => {
        // do not execute while announcement still show
        console.log(`isShowAnnouncement => ${isShowAnnouncement}`)
        if (isShowAnnouncement) return;

        unityContext.on("error", function (message) {
            console.error("error: ", message)
            setDidError(true)
            setErrorMessage(message)

            // log event - login with metamask
            if (analytics) {
                logEvent(analytics, 'unity_load_error', { error: message });
            }
        });

        unityContext.on("loaded", function () {
            setIsLoaded(true)
            if (analytics) {
                logEvent(analytics, 'unity_load_success');
            }
        });

        unityContext.on("progress", function (p) {
            setProgress(p);
        });

        registerPluginFunctions({
            getForceNoShadowFunc: () => forceNoShadow,
        });
        RequestLoginTypeHandler(unityContext);
        RequestBitkubAuthenticationInfoHandler(unityContext, history);
        NavigateToLoginHandler(unityContext, history);
    }, [isShowAnnouncement]);

    if (!isAuthenticated) {
        return (<Loading isLoaded={false} />)
    }

    // if (didError) {
    //     return (
    //         <div>
    //             <ErrorPage isLoaded={isLoaded} errorMessage={errorMessage} />
    //         </div>
    //     )
    // }

    return (
        <div>
            <Loading isLoaded={isLoaded} progress={progress} />
            <div className="unity-container" style={{ display: isLoaded ? "block" : "none" }}>
                {!isShowAnnouncement ? <Unity
                    unityContext={unityContext}
                    style={config.allowFullscreen ? fullscreenStyle : null}
                /> : null}
            </div>
        </div>
    );

    // return didError === true ? (
    //     <div>
    //         <ErrorPage isLoaded={isLoaded} errorMessage={errorMessage} />
    //     </div>
    // ) : (
    //     <div>
    //         <Loading isLoaded={isLoaded} progress={progress} />
    //         <div className="unity-container" style={{ display: isLoaded ? "block" : "none" }}>
    //             <Unity
    //                 unityContext={unityContext}
    //                 style={config.allowFullscreen ? fullscreenStyle : null}
    //             />
    //         </div>
    //     </div>
    // );
}

const FullscreenButton = (props) => (
    <div id={props.id}>
        <input
            type="button"
            onClick={async () => {
                console.log("go to fullscreen mode...");
                unityContext.setFullscreen(true);
            }}
            value={props.title}
        ></input>
    </div>
);

async function registerPluginFunctions(dependencies) {
    let wrapperFunc = null;
    const logTag = "[registerPluginFunctions]";
    // const networkAddress = RPC_TESTNET;
    const networkAddress = RPC_MAINNET; // this line must be uncommented in main branch
    const provider = new Web3.providers.HttpProvider(networkAddress);
    web3Instance = new Web3(provider);

    console.dir(`${logTag} web3Instance => ${web3Instance}`);

    originalChainId = await web3Instance.eth.getChainId();

    console.log(`${logTag} chainId = ${originalChainId}`);
    web3Instance.eth.getNodeInfo().then(console.log);

    unityContext.on("JSPluginWrapper_Init", async (callID, args) => {
        const logTag = "[JSPluginWrapper_Init]";
        console.log(`${logTag} called with callID: ${callID} `);
        console.log(`${logTag} args => `, args);

        const {
            gameObjectName,
            callbackEntryPointMethod,
            reportTxEventMethod,
        } = JSON.parse(args);

        console.log(`${logTag} unityCB_GameObjectName: ${gameObjectName} `);
        console.log(
            `${logTag} unityCB_CallbackEntryPointMethodName: ${callbackEntryPointMethod} `
        );
        console.log(
            `${logTag} unityCB_ReportTxEventMethodName: ${reportTxEventMethod} `
        );

        // init wrapperFunc instance
        wrapperFunc = new ContractFuncWrapper(
            () => web3Instance,
            unityContext,
            gameObjectName,
            callbackEntryPointMethod,
            reportTxEventMethod,
            process.env.REACT_APP_MMV_MULTICALL_ADDRESS,
        );
        console.log(`${logTag} wrapperFunc instance created...`, wrapperFunc);
        console.log(`${logTag} multicall address:`, process.env.REACT_APP_MMV_MULTICALL_ADDRESS);
    });

    unityContext.on("JSPluginWrapper_ConnectWallet", async (callID) => {
        const logTag = "[JSPluginWrapper_ConnectWallet]";
        await connectWallet(wrapperFunc, callID);
    });

    unityContext.on("JSPluginWrapper_GetLumiBalance", async (callID) => {
        const logTag = "[JSPluginWrapper_GetLumiBalance]";
        const balance = await getLumiBalance();
        wrapperFunc.unitySend(callID, { balance }, null);
    });

    unityContext.on("JSPluginWrapper_GetBackendAPIURL", async (callID) => {
        const logTag = "[JSPluginWrapper_GetBackendAPIURL]";
        wrapperFunc.unitySend(callID, { url: config.backendURL }, null);
    });

    unityContext.on("JSPluginWrapper_CallContractFunction", async (callID, funcName, args) => {
        const logTag = "[JSPluginWrapper_CallContractFunction]";
        console.log(`${logTag} called with callID: ${callID} funcName: ${funcName} `);
        wrapperFunc.executeContractFunction(callID, funcName, args);

        try {
            const analytics = getAnalytics();
            logEvent(analytics, 'rpc_call_v1', { name: funcName });
        } catch (err) { }
    });

    unityContext.on(UnityEvent.RequestClientIdentity, async () => {
        const logTag = "[RequestClientIdentity]";
        const resp = {
            "mfp": machineFingerprint,
            "scuid": screenUID,
        };
        unityContext.send(
            UnityGameObject.WebContainerListener,
            UnityMethod.RequestClientIdentityCallback,
            JSON.stringify(resp));
    })

    unityContext.on(UnityEvent.RequestTier1Captcha, async () => {
        const logTag = "[RequestTier1Captcha]";
        let token = "";
        try {
            token = await window.grecaptcha.execute(
                process.env.REACT_APP_MMV_RECAPTCHA_SITE_KEY,
                { action: 'submit' },
            );
            console.log(logTag, "get tier1 captcha token:", token);
        } catch (err) {
            console.log(`${logTag} failed to get recaptcha v3 token: ${err}`);
            token = "";
        }

        unityContext.send(
            UnityGameObject.WebContainerListener,
            UnityMethod.RequestTier1CaptchaCallback,
            JSON.stringify({
                "captchaToken": token,
                "type": "tier1",
            }));
    });

    unityContext.on(UnityEvent.RequestTier2Captcha, async () => {
        const logTag = "[RequestTier2Captcha]";
        showTier2Captcha((resp) => {
            // resp is hcaptcha returned token...
            unityContext.send(
                UnityGameObject.WebContainerListener,
                UnityMethod.RequestTier2CaptchaCallback,
                JSON.stringify({
                    "captchaToken": resp,
                    "type": "tier2",
                }));
        });
    })

    unityContext.on(UnityEvent.CheckIfSetForceNoShadow, async () => {
        const logTag = "[CheckIfSetForceNoShadow]";
        let forceNoShadow = false;
        if (dependencies.getForceNoShadowFunc) {
            forceNoShadow = dependencies.getForceNoShadowFunc();
        }
        unityContext.send(
            UnityGameObject.WebContainerListener,
            UnityMethod.CheckIfSetForceNoShadowCallback,
            JSON.stringify({
                "forceNoShadow": forceNoShadow,
            }));
    })

    unityContext.on()
}

async function showTier2Captcha(successCallback = null) {
    swal.fire({
        title: 'Verification Process',
        html: '<div id="captcha-wildmap" class="h-captcha"></div>',
        backdrop: false,
        showConfirmButton: false,
        showCancelButton: false,
        showDenyButton: false,
        didOpen: () => {
            console.log("sitekey:", process.env.REACT_APP_HCAPTCHA_SITE_KEY);
            window.hcaptcha.render('captcha-wildmap', {
                sitekey: process.env.REACT_APP_HCAPTCHA_SITE_KEY,
                theme: 'light',
                'error-callback': (err) => { console.error(`hcaptcha error: ${err}`) },
                'callback': (resp) => {
                    if (resp.length === 0) {
                        return;
                    }
                    console.log(resp);
                    if (successCallback) {
                        successCallback(resp);
                    }
                    swal.close();
                },
            });
        },
    });
}

async function getLumiBalance() {
    const LOG_TAG = "[getLumiBalance]";
    if (!limuContract) {
        swal.fire({
            title: "Error",
            text: "contract does not initialize",
        });
        return;
    }

    const balance = await limuContract.methods
        .balanceOf(web3Instance.eth.defaultAccount)
        .call();
    return balance;
}

async function connectWallet(wrapperFunc, callID) {
    const logTag = "connectWallet";

    if (typeof window.ethereum === 'undefined') {
        console.log(`${logTag} MetaMask is not installed!`);
        wrapperFunc.unitySend(callID, null, { "code": 101, "message": "MetaMask is not installed" });
        swal.fire({
            title: "Error",
            text: "Metamask is not installed!",
        }).then((_) => {
            unityContext.dispatchEventListener(UnityEvent.NavigateToLogin);
        });
        return;
    }

    try {
        const provider = await web3Modal.connect();
        if (!provider) {
            return;
        }
        web3Instance = new Web3(provider);
        const currentChainId = await web3Instance.eth.getChainId();
        const isAllowToPlay = currentChainId === originalChainId;
        console.log(`${logTag} original chainId: ${originalChainId} `);
        console.log(`${logTag} currentChainId to ${currentChainId} `);
        console.log(`${logTag} isAllowToPlay: ${isAllowToPlay} `);

        if (!isAllowToPlay) {
            unityContext.send(UnityGameObject.WebContainerListener, UnityMethod.OnReceiveChainChanged, 0);
            provider.on("chainChanged", async (chainId) => {
                const isPass = onChainChanged(chainId);
                if (isPass) {
                    await setupDefaultAccount(provider, wrapperFunc, callID);
                }
            });
        } else {
            await setupDefaultAccount(provider, wrapperFunc, callID);
        }
    } catch (e) {
        console.error(e);
    }
}

function onChainChanged(chainId) {
    const LOG_TAG = "onChainChanged";
    const newChainId = parseInt(chainId, 16);
    const isAllowToPlay = newChainId === originalChainId;
    console.log(`${LOG_TAG} original chainId: ${originalChainId} `);
    console.log(`${LOG_TAG} chainChanged to ${chainId} | ${newChainId} `);
    console.log(`${LOG_TAG} isAllowToPlay: ${isAllowToPlay} `);
    const response = isAllowToPlay ? 1 : 0;
    unityContext.send(UnityGameObject.WebContainerListener, UnityMethod.OnReceiveChainChanged, response);
    return isAllowToPlay;
}

async function setupDefaultAccount(provider, wrapperFunc, callID) {
    setupWeb3ProviderListener(provider);

    const logTag = "setupDefaultAccount";
    const accounts = await web3Instance.eth.getAccounts();
    web3Instance.eth.defaultAccount = accounts[0];

    console.log(`${logTag} wallet connected`);
    wrapperFunc.unitySend(callID, null, null);
}

function setupWeb3ProviderListener(provider) {
    const LOG_TAG = "[provider]";

    // Subscribe to accounts change
    provider.on("accountsChanged", (accounts) => {
        console.log(`${LOG_TAG} accountsChanged...`);
        console.dir(accounts);
        window.location.reload();
    });

    // Subscribe to chainId change
    provider.on("chainChanged", (chainId) => {
        onChainChanged(chainId)
    });

    // Subscribe to provider connection
    provider.on("connect", (info) => {
        console.log(`${LOG_TAG} connect...`);
        console.dir(info);
    });

    // Subscribe to provider disconnection
    provider.on("disconnect", (error) => {
        console.log(`${LOG_TAG} disconnect... ${error} `);
    });
}

export default App;